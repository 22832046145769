var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "verify-page" },
    [
      _c("HeadNav"),
      _c(
        "div",
        { staticClass: "verify-group" },
        [
          _c(
            "yd-cell-group",
            [
              _vm.function_type === 1 || _vm.function_type === 2
                ? _c(
                    "yd-cell-item",
                    [
                      _c("span", { attrs: { slot: "left" }, slot: "left" }, [
                        _vm._v("手机号"),
                      ]),
                      _c("yd-input", {
                        attrs: {
                          slot: "right",
                          disabled: "",
                          required: "",
                          "show-success-icon": false,
                          "show-error-icon": false,
                          "show-clear-icon": false,
                          regex: "mobile",
                          placeholder: "11位手机号码",
                        },
                        slot: "right",
                        model: {
                          value: _vm.mobile,
                          callback: function ($$v) {
                            _vm.mobile = $$v
                          },
                          expression: "mobile",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.function_type === 3
                ? _c(
                    "yd-cell-item",
                    [
                      _c("span", { attrs: { slot: "left" }, slot: "left" }, [
                        _vm._v("新手机号"),
                      ]),
                      _c("yd-input", {
                        attrs: {
                          slot: "right",
                          required: "",
                          "show-success-icon": false,
                          "show-error-icon": false,
                          "show-clear-icon": false,
                          regex: "mobile",
                          placeholder: "11位手机号码",
                        },
                        slot: "right",
                        model: {
                          value: _vm.mobile,
                          callback: function ($$v) {
                            _vm.mobile = $$v
                          },
                          expression: "mobile",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "df fdr alc verify-item border-bottom-1px" },
            [
              _c("span", { staticClass: "yd-cell-left" }, [_vm._v("图片验证")]),
              _c(
                "div",
                { staticClass: "verify-right" },
                [
                  _c("yd-input", {
                    attrs: {
                      required: "",
                      "show-clear-icon": false,
                      "show-success-icon": false,
                      "show-error-icon": false,
                      placeholder: "输入图片验证码",
                      max: "6",
                    },
                    model: {
                      value: _vm.code,
                      callback: function ($$v) {
                        _vm.code = $$v
                      },
                      expression: "code",
                    },
                  }),
                ],
                1
              ),
              _c("img", {
                staticClass: "verify-img",
                attrs: { src: _vm.img_code },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.getRandomImgCode.apply(null, arguments)
                  },
                },
              }),
            ]
          ),
          _c(
            "div",
            { staticClass: "df fdr alc jc-sb verify-item border-bottom-1px" },
            [
              _c("div", { staticClass: "df fdr" }, [
                _c("span", { staticClass: "yd-cell-left" }, [_vm._v("验证码")]),
                _c(
                  "div",
                  { staticClass: "verify-right" },
                  [
                    _c("yd-input", {
                      attrs: {
                        required: "",
                        "show-clear-icon": false,
                        "show-success-icon": false,
                        "show-error-icon": false,
                        placeholder: "验证码",
                        max: "6",
                      },
                      model: {
                        value: _vm.verify_code,
                        callback: function ($$v) {
                          _vm.verify_code = $$v
                        },
                        expression: "verify_code",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("yd-sendcode", {
                attrs: {
                  slot: "right",
                  type: "warning",
                  "storage-key": "storage",
                  second: "30",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.sendCode.apply(null, arguments)
                  },
                },
                slot: "right",
                model: {
                  value: _vm.start,
                  callback: function ($$v) {
                    _vm.start = $$v
                  },
                  expression: "start",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.function_type === 1 || _vm.function_type === 2
        ? _c("div", { staticClass: "save-box" }, [
            _c(
              "button",
              {
                staticClass: "save-btn",
                attrs: { disabled: _vm.is_disabled },
                on: { click: _vm.submit },
              },
              [_vm._v("\n      下一步\n    ")]
            ),
          ])
        : _vm._e(),
      _vm.function_type === 3
        ? _c("div", { staticClass: "save-box" }, [
            _c(
              "button",
              {
                staticClass: "save-btn",
                attrs: { disabled: _vm.is_disabled },
                on: { click: _vm.submit },
              },
              [_vm._v("\n      确认新绑定\n    ")]
            ),
          ])
        : _vm._e(),
      _c("alert-tip", {
        attrs: {
          type: _vm.type,
          "show-alert": _vm.show_tip,
          "alert-text": _vm.tip,
        },
        on: { changeShowAlert: _vm.changeShow },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }